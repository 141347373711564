
            @import "@/assets/sass/vars.portal.scss";
          



































































































































































































































































































































































































































































































































































































































































































































































































































































.v-stats-rp {
    flex: 0 0 300px;
}

.extra-filter {
    .form-group {
        margin-bottom: 0px !important;
    }
}

.mini-belt {
    text-align: center;
    h5 {
        margin-bottom: 2px;
    }

    &__value {
        font-weight: bold;
    }

    &__perc {
        margin-top: 1px;
    }
}
